import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Spacer } from "ui-start";
import { HeroImage } from "../components/heroImage";
import Layout from "../components/layout";
import {
  DirectorImageWrapper,
  DirectorsLayout,
  StyledPageCopy,
  StyledPageSubHeading,
  Wrapper,
} from "../components/styles";

const Directors = () => {
  const data = useStaticQuery(graphql`
    query directorsPageQuery {
      allGoogleSheet {
        edges {
          node {
            directors {
              director
              copy
              url
            }
          }
        }
      }
    }
  `);

  const pageData = data?.allGoogleSheet?.edges?.[0]?.node?.directors;

  return (
    <Layout>
      <HeroImage page="directors" />
      <Wrapper>
        <Spacer height={32} />
        {pageData?.map(({ director, copy, url }, i) => (
          <>
            {i !== 0 && <Spacer height={16} />}
            <DirectorsLayout>
              <div>
               <StyledPageSubHeading>{director}</StyledPageSubHeading>
                <DirectorImageWrapper>
                <img src={url} alt={director} />
                </DirectorImageWrapper>
              </div>
              <StyledPageCopy dangerouslySetInnerHTML={{ __html: copy }} />
            </DirectorsLayout>
              {i !== pageData.length - 1 &&
              <>
                <Spacer height={48} />
                <div style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#e5e5e5",
                }} />
              </>}
            </>
        ))}
      </Wrapper>
    </Layout>
  );
};

export default Directors;
